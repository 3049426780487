const adminHeaders = [
  { Header: "Nama Lengkap", accessor: "name", css: "", default: "" },
  {
    Header: "No. Registrasi",
    accessor: "registration_id",
    css: "",
    default: "",
    inputDisabled: true,
  },
  { Header: "Alamat Lengkap", accessor: "address", css: "", default: "" },
  {
    Header: "Tanggal Pendaftaran",
    accessor: "registration_date",
    css: "",
    default: new Date(),
    inputType: "datepicker",
  },
  { Header: "Nomor HP", accessor: "phone", css: "", default: "" },
];

const dokterHeaders = [
  {
    Header: "Nama Lengkap",
    accessor: "name",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
  },
  {
    Header: "ID Dokter",
    accessor: "registration_id",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
    inputDisabled: true,
  },
  {
    Header: "Email",
    accessor: "email",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
  },
  {
    Header: "Spesialis",
    accessor: "specialisation",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
  },
  {
    Header: "Alamat",
    accessor: "address",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
  },
  {
    Header: "Nomor Telepon",
    accessor: "phone",
    css: "min-w-table whitespace-nowrap max-w-table overflow-hidden text-ellipsis",
    default: "",
  },
];

const pasienHeaders = [
  { Header: "Nama Lengkap", accessor: "name", css: "", default: "" },
  {
    Header: "No. Registrasi",
    accessor: "registration_id",
    css: "",
    default: "",
    inputDisabled: true,
  },
  {
    Header: "Jenis Kelamin",
    accessor: "gender",
    css: "",
    default: { value: "male", label: "Laki-Laki" },
    inputType: "dropdown",
    options: [
      { value: "male", label: "Laki-Laki" },
      { value: "female", label: "Perempuan" },
    ],
  },
  {
    Header: "Umur",
    accessor: "age",
    css: "",
    default: 0,
    inputType: "number",
  },
  { Header: "Alamat Lengkap", accessor: "address", css: "", default: "" },
  {
    Header: "Tanggal Lahir",
    accessor: "birthday",
    css: "",
    default: new Date(),
    inputType: "datepicker",
  },
  {
    Header: "Tanggal Pendaftaran",
    accessor: "registration_date",
    css: "",
    default: new Date(),
    inputType: "datepicker",
  },
  { Header: "Pekerjaan", accessor: "occupation", css: "", default: "" },
  {
    Header: "Status Pernikahan",
    accessor: "marriage",
    css: "",
    default: { value: "married", label: "Kawin" },
    inputType: "dropdown",
    options: [
      { value: "married", label: "Kawin" },
      { value: "nevermarried", label: "Belum Kawin" },
      { value: "divorce", label: "Cerai Hidup" },
      { value: "widowed", label: "Cerai Mati" },
    ],
  },
  { Header: "Nama Wali/Ortu", accessor: "parentname", css: "", default: "" },
  { Header: "Nomor HP", accessor: "phone", css: "", default: "" },
  { Header: "Riwayat Alergi", accessor: "allergen", css: "", default: "" },
];

const ruangHeader = [
  { Header: "Nama Ruang", accessor: "name", css: "", default: "" },
  {
    Header: "ID Ruang",
    accessor: "registration_id",
    css: "",
    default: "",
    inputDisabled: true,
  },
  { Header: "Keterangan", accessor: "details", css: "", default: "" },
];

const obatHeader = [
  { Header: "Nama Obat", accessor: "name", css: "", default: "" },
  {
    Header: "ID Obat",
    accessor: "registration_id",
    css: "",
    default: "",
    inputDisabled: true,
  },
  { Header: "Keterangan", accessor: "details", css: "", default: "" },
];

const rekamMedisAdminHeader = [
  {
    Header: "Tanggal Keluhan",
    accessor: "complaint_date",
    css: "",
    default: new Date(),
    inputType: "datepicker",
  },
  {
    Header: "Nama Pasien",
    accessor: "name",
    css: "",
    default: "",
    inputDisabled: true,
  },
  {
    Header: "Nama Dokter",
    accessor: "doctor_name",
    css: "",
    default: [],
    inputType: "dropdown",
  },
  {
    Header: "Ruang",
    accessor: "room_name",
    css: "",
    default: [],
    inputType: "dropdown",
  },
  {
    Header: "Keluhan",
    accessor: "complaint",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "ID Pasien",
    accessor: "patient_id",
    css: "",
    default: "",
    hidden: true,
  },
];

const rekamMedisDokterHeader = [
  { Header: "Diagnosa", accessor: "diagnose", css: "", default: "" },
  { Header: "Nama Obat", accessor: "recipes", css: "", default: [] },
  { Header: "Pemeriksaan", accessor: "examination", css: "", default: "" },
  { Header: "Terapi", accessor: "therapy", css: "", default: "" },
];

const antreanHeader = [
  {
    Header: "Tanggal Keluhan",
    accessor: "complaint_date",
    css: "",
    default: "",
    inputType: "date",
  },
  {
    Header: "Keluhan",
    accessor: "complaint",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "Pemeriksaan",
    accessor: "examination",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "Diagnosa",
    accessor: "diagnose",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "Saran & Edukasi",
    accessor: "therapy",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "Resep Obat",
    accessor: "recipes",
    css: "",
    default: "",
    inputType: "recipes",
  },
];

const historyHeaders = [
  {
    Header: "Tanggal Keluhan",
    accessor: "complaint_date",
    css: "",
    default: new Date(),
    inputType: "datepicker",
  },
  {
    Header: "Nama Pasien",
    accessor: "name",
    css: "",
    default: "",
    inputDisabled: true,
  },
  {
    Header: "Nama Dokter",
    accessor: "doctor_name",
    css: "",
    default: [],
    inputType: "dropdown",
  },
  {
    Header: "Ruang",
    accessor: "room_name",
    css: "",
    default: [],
    inputType: "dropdown",
  },
  {
    Header: "Keluhan",
    accessor: "complaint",
    css: "",
    default: "",
    inputType: "textarea",
  },
  {
    Header: "ID Pasien",
    accessor: "patient_id",
    css: "",
    default: "",
    hidden: true,
  },
];

export {
  adminHeaders,
  dokterHeaders,
  pasienHeaders,
  ruangHeader,
  obatHeader,
  rekamMedisAdminHeader,
  rekamMedisDokterHeader,
  antreanHeader,
  historyHeaders,
};
