import classNames from "classnames";
import styles from "./splash.module.scss";

const Splash = ({ size }) => (
  <div
    className={classNames(
      styles["gg-spinner"],
      styles[size] ? styles[size] : null
    )}
  ></div>
);

export default Splash;

export const SplashFullPage = ({ size = "xl" }) => (
  <div className="flex justify-center w-screen h-screen place-items-center">
    <Splash size={size} />
  </div>
);
