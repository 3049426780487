import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import Splash from "../../assets/UIElements/Splash";
import { useAuth } from "./context/AuthContext";
import Alert from "./UIComponents/Alert";
import AxiosFetch from "../../utils/AxiosFetch";
const Signup = () => {
  const status = "";

  const [error, setError] = useState("");

  const { signup } = useAuth(); // const { signup, currentUser } = useAuth();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [formInputValue, setFormInputValue] = useState({
    email: "",
    fullname: "",
    password: "",
    confirmpassword: "",
  });

  const handleInputChange = async (e) => {
    setFormInputValue({
      ...formInputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formInputValue.password !== formInputValue.confirmpassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(
        formInputValue.email,
        formInputValue.password,
        formInputValue.fullname
      )
        .then((response) => {
          let user_data = cloneDeep(response.user);
          response.user.updateProfile({ displayName: formInputValue.fullname });
          let body = {
            parent_uid: "",
            user_uid: user_data?.uid,
            account_types: ["admin", "doctor"],
            email: user_data?.email,
            displayName: formInputValue.fullname,
          };

          let config = {
            url: "/users",
            method: "post",
            data: JSON.stringify(body),
          };
          AxiosFetch(config).then((resp) => {
            let get_config = {
              url: `/userbyemail/?email=${user_data?.email}`,
              method: "get",
              headers: {
                accept: "application/json",
              },
            };

            AxiosFetch(get_config).then((res) => {
              let storageInfo = {};
              if (res.data.account_types.includes("doctor")) {
                storageInfo = { ...storageInfo, is_doctor: true };
                storageInfo = { ...storageInfo, dokter_id: res.data.dokter_id };
              }

              if (res.data.account_types.includes("admin")) {
                storageInfo = { ...storageInfo, is_admin: true };
                storageInfo = { ...storageInfo, user_uid: res.data.user_uid };
              }

              localStorage.setItem("loginInfo", JSON.stringify(storageInfo));
              history.push("/");
            });
          });
        })
        .catch((err) => {
          if (err.code === "auth/email-already-in-use") {
            setError("Email already in Use!");
          }
        });
    } catch (err) {
      console.log(err);
      setError("Failed to create an account");
    }

    setLoading(false);

    // doLogin(dispatch, values.username, values.password);
    // setValues({ username: "", password: "" });
  };

  return (
    <>
      <div className="flex min-h-screen">
        {/* boundary top */}

        <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="w-full h-full max-w-sm mx-auto 2xl:my-40 lg:w-96">
            <div>
              <img
                className="w-auto h-12 py-1"
                src={
                  "https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
                }
                alt="Workflow"
              />
              <h2 className="mt-8 text-3xl font-extrabold text-gray-900">
                Register
              </h2>

              {error && <Alert error={error} />}
            </div>

            <div className="relative mt-6">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text-gray-500 bg-white">
                  Create an Account
                </span>
              </div>
            </div>

            <div className="mt-8">
              <div>
                <div className="relative mt-6">
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-base text-red-700">
                      {status === "rejected" && <h3>{error}</h3>}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="fullname"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        // ref={inputRef}
                        id="fullname"
                        name="fullname"
                        type="text"
                        required
                        className={`${
                          error
                            ? "ring-red-500 border-red-500"
                            : "focus:ring-indigo-500 focus:border-indigo-500"
                        } block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none  sm:text-sm`}
                        value={formInputValue.fullname}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username or Email
                    </label>
                    <div className="mt-1">
                      <input
                        // ref={inputRef}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className={`${
                          error
                            ? "ring-red-500 border-red-500"
                            : "focus:ring-indigo-500 focus:border-indigo-500"
                        } block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none  sm:text-sm`}
                        value={formInputValue.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between space-x-4">
                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          className={`${
                            error
                              ? "ring-red-500 border-red-500"
                              : "focus:ring-indigo-500 focus:border-indigo-500"
                          } block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none  sm:text-sm`}
                          value={formInputValue.password}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="confirmpassword"
                          name="confirmpassword"
                          type="password"
                          required
                          className={`${
                            error
                              ? "ring-red-500 border-red-500"
                              : "focus:ring-indigo-500 focus:border-indigo-500"
                          } block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none  sm:text-sm`}
                          value={formInputValue.confirmpassword}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {loading ? <Splash /> : "Submit"}
                    </button>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="flex items-center"></div>
                    <div className="text-sm">
                      <Link to={`/login`}>
                        <span className="font-medium text-indigo-600 hover:text-indigo-500">
                          Already have an account?
                        </span>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* boundary */}
        <div className="relative flex-1 hidden w-0 lg:block">
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Signup;
