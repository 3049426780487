import React, { useState, useRef, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Splash from "../../assets/UIElements/Splash";
import { useAuth } from "./context/AuthContext";
import Alert from "./UIComponents/Alert";
import SuccessAlert from "./UIComponents/SuccessAlert";
const ForgotPassword = () => {
  const status = "";

  const [error, setError] = useState("");

  const { resetPassword } = useAuth(); // const { signup, currentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");

  const [formInputValue, setFormInputValue] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = async (e) => {
    setFormInputValue({
      ...formInputValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(formInputValue.email);
      setMessage("check your email inbox for further instructions");
    } catch {
      setLoading(false);
      return setError("Failed to Reset Password");
    }

    setLoading(false);

    // doLogin(dispatch, values.username, values.password);
    // setValues({ username: "", password: "" });
  };

  return (
    <>
      <div className="flex min-h-screen">
        {/* boundary top */}

        <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="w-full h-full max-w-sm mx-auto lg:my-40 lg:w-96">
            <div>
              <img
                className="w-auto h-12 py-1"
                src={`https://route.nextsci.com/static/media/routerlogo.eb2a92944278f74c1446.png`}
                alt="Workflow"
              />
              <h2 className="mt-8 text-3xl font-extrabold text-gray-900">
                Reset Password
              </h2>

              {error && <Alert error={error} />}
              {message && <SuccessAlert message={message} />}
            </div>

            <div className="relative mt-6">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text-gray-500 bg-white">
                  Reset your Password
                </span>
              </div>
            </div>

            <div className="mt-8">
              <div>
                <div className="relative mt-6">
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-base text-red-700">
                      {status === "rejected" && <h3>{error}</h3>}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username or Email
                    </label>
                    <div className="mt-1">
                      <input
                        // ref={inputRef}
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className={`${
                          error
                            ? "ring-red-500 border-red-500"
                            : "focus:ring-indigo-500 focus:border-indigo-500"
                        } block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none  sm:text-sm`}
                        value={formInputValue.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {loading ? <Splash /> : "Reset Password"}
                    </button>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="text-sm">
                      <Link to={`/login`}>
                        <span className="font-medium text-indigo-600 hover:text-indigo-500">
                          Login
                        </span>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* boundary */}
        <div className="relative flex-1 hidden w-0 lg:block">
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
