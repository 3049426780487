import React from "react";

const Pagination = ({ pagination, setPagination, paginationSetting }) => {
  return (
    <nav
      className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium">
            {pagination.page * paginationSetting.limit + 1}
          </span>{" "}
          to{" "}
          <span className="font-medium">
            {(pagination.page + 1) * paginationSetting.limit}
          </span>{" "}
          of <span className="font-medium">{pagination.totalResults}</span>{" "}
          results
        </p>
      </div>
      <div className="flex justify-between flex-1 sm:justify-end">
        <button
          onClick={() =>
            setPagination({ ...pagination, page: pagination.page - 1 })
          }
          disabled={pagination.page <= 0}
          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md disabled:text-gray-300 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={() =>
            setPagination({ ...pagination, page: pagination.page + 1 })
          }
          disabled={pagination.page >= pagination.totalPage - 1}
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:text-gray-300"
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
