import React from "react";
import Dokter from "../Dokter/Dokter";
import { pasienHeaders } from "../../components/TableHeaders";

const QUERY_PASIEN = "/pasien";
const Pasien = () => {
  return (
    <Dokter
      queryUrl={QUERY_PASIEN}
      headers={pasienHeaders}
      mainTitle="Pasien"
      mainDetails="Masukkan Data Pasien"
    />
  );
};

export default Pasien;
