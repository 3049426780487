import axios from "axios";

const AxiosFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "http://207.180.226.31:8090/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default AxiosFetch;
