import React, { useState, useEffect } from "react";
import { UserAddIcon } from "@heroicons/react/outline";
import AddData from "../Dokter/AddData";
import { rekamMedisAdminHeader } from "../../components/TableHeaders";
import AxiosFetch from "../../utils/AxiosFetch";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";

const ADMIN_REKAM_MEDIS_URL = "/rekammedis";
const ProfileHeader = ({
  profile,
  pagination,
  setPagination,
  mainTitle,
  mainDetails,
  setData,
  paginationSetting,
}) => {
  const { currentUser } = useAuth();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [rekamMedisAdminHeaderDynamic, setRekamMedisAdminHeaderDynamic] =
    useState(rekamMedisAdminHeader);

  // update the first dokter choices
  useEffect(() => {
    let config = {
      method: "get",
      url: `/rekammedis/dropdownadd?user_uid=${
        currentUser?.uid || ""
      }&page=0&limit=10`,
    };
    AxiosFetch(config).then((res) => {
      let temp = [];
      for (let i = 0; i < rekamMedisAdminHeaderDynamic.length; i++) {
        if (rekamMedisAdminHeaderDynamic[i].accessor === "doctor_name") {
          temp.push({
            ...rekamMedisAdminHeaderDynamic[i],
            default: res.data.dokter_dropdown[0],
            options: res.data.dokter_dropdown,
          });
        } else if (rekamMedisAdminHeaderDynamic[i].accessor === "room_name") {
          temp.push({
            ...rekamMedisAdminHeaderDynamic[i],
            default: res.data.ruang_dropdown[0],
            options: res.data.ruang_dropdown,
          });
        } else if (rekamMedisAdminHeaderDynamic[i].accessor === "name") {
          temp.push({
            ...rekamMedisAdminHeaderDynamic[i],
            default: profile?.name || "",
          });
        } else if (rekamMedisAdminHeaderDynamic[i].accessor === "patient_id") {
          temp.push({
            ...rekamMedisAdminHeaderDynamic[i],
            default: profile?.patient_id || "",
          });
        } else {
          temp.push(rekamMedisAdminHeaderDynamic[i]);
        }
      }

      // console.log("temp daftar antre form", temp);

      setRekamMedisAdminHeaderDynamic(temp);
    });
  }, [profile]);

  const getTwoChar = (text) => {
    let nameArr = text.split(" ");

    try {
      if (nameArr.length > 1) {
        return nameArr[0].slice(0, 1) + nameArr[nameArr.length - 1].slice(0, 1);
      } else if (nameArr.length === 1) {
        return nameArr[0].slice(0, 1);
      } else {
        return "RM";
      }
    } catch {
      return "";
    }
  };

  return (
    <>
      <div>
        <div>
          <img
            className="z-0 object-cover w-full h-32 lg:h-48"
            src={profile.coverImageUrl}
            alt=""
          />
        </div>
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex flex-col items-center justify-center">
              {/* <span className="inline-flex items-center justify-center w-24 h-24 bg-gray-500 rounded-full ring-4 ring-white sm:w-32 sm:h-32">
                        <span className="relative w-24 h-24 text-3xl font-medium leading-none text-center text-white sm:h-32 sm:w-32">
                          {profile.name
                            .split(" ")
                            .slice(0, 2)
                            .map((elem) => elem[0])
                            .join("")}
                        </span>
                      </span> */}
              {/* <img
                className="z-0 w-24 h-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={profile.imageUrl}
                alt=""
              /> */}
              <div className="flex items-center justify-center w-24 h-24 font-mono text-6xl font-bold text-white rounded-full bg-slate-400 sm:h-32 sm:w-32">
                {getTwoChar(profile.name)}
              </div>
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
                <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {profile.name}
                </h1>
              </div>
              <div className="flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4">
                <button
                  type="button"
                  onClick={() => setAddModalOpen(true)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-gray-300 rounded-md shadow-sm hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <UserAddIcon
                    className="w-5 h-5 mr-2 -ml-1"
                    aria-hidden="true"
                  />
                  <span>Tambah ke Antrean</span>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
            <h1 className="text-2xl font-bold text-gray-900 truncate">
              {profile.name}
            </h1>
          </div>
        </div>
      </div>
      <AddData
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        headers={rekamMedisAdminHeaderDynamic}
        addUrl={ADMIN_REKAM_MEDIS_URL}
        getUrl={ADMIN_REKAM_MEDIS_URL}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        mainTitle={mainTitle}
        mainDetails={mainDetails}
        paginationSetting={paginationSetting}
      />
    </>
  );
};

export default ProfileHeader;
