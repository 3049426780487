import "./App.css";
import { useEffect, useState } from "react";

// import Login from "./pages/Login";
import Login from "./components/FirebaseAuthComponents/Login";
import Signup from "./components/FirebaseAuthComponents/Signup";
import SidebarNav from "./components/SidebarNav";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import RekamMedis from "./pages/RekamMedis";
import Dokter from "./pages/Dokter";
import Ruang from "./pages/Ruang";
import Pasien from "./pages/Pasien";
import Obat from "./pages/Obat";
import Antrean from "./pages/Antrean";
import Histories from "./pages/Histories";
import PrivateRoute from "./components/FirebaseAuthComponents/PrivateRoute";
import ForgotPassword from "./components/FirebaseAuthComponents/ForgotPassword";
import UpdateProfile from "./components/FirebaseAuthComponents/UpdateProfile";
import { useAuth } from "./components/FirebaseAuthComponents/context/AuthContext";
function App() {
  const { currentUser } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (currentUser !== null) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  return (
    <Router>
      <div className="flex justify-between">
        {isLoggedIn ? <SidebarNav /> : ""}
        <Switch>
          <PrivateRoute path="/" exact>
            <Redirect to="/dashboard" />
          </PrivateRoute>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute path="/pasien" component={Pasien} />
          <PrivateRoute path="/ruang" component={Ruang} />
          <PrivateRoute path="/rekammedis" component={RekamMedis} />
          <PrivateRoute path="/dokter" component={Dokter} />
          <PrivateRoute path="/obat" component={Obat} />
          <PrivateRoute path="/antrean" component={Antrean} />
          <PrivateRoute path="/history" component={Histories} />

          {currentUser == null ? (
            <Route path="/login" component={Login} />
          ) : (
            <Route path="/login" exact>
              <Redirect to="/dashboard" />
            </Route>
          )}
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/register" component={Signup} />
          <PrivateRoute path="/update-profile" component={UpdateProfile} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
