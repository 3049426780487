import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
// import { AuthProvider } from "./context/AuthContext";
import { AuthProvider } from "./components/FirebaseAuthComponents/context/AuthContext";
axios.defaults.baseURL =
  process.env.REACT_APP_BASE_URL || "http://207.180.226.31:8090";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
