import React from "react";
import Dokter from "../Dokter/Dokter";
import { adminHeaders } from "../../components/TableHeaders";

const QUERY_ADMIN = "/admin";
const Admin = () => {
  return (
    <Dokter
      queryUrl={QUERY_ADMIN}
      headers={adminHeaders}
      mainTitle="Admin"
      mainDetails="Masukkan Data Admin"
    />
  );
};

export default Admin;
