import React from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
const SuccessAlert = ({ message }) => {
  return (
    <div className="p-4 mt-4 rounded-md bg-blue-50">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="w-5 h-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="flex-1 ml-3 md:flex md:justify-between">
          <p className="text-sm text-blue-700">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessAlert;
