import { CheckCircleIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/solid";
import AxiosFetch from "../../utils/AxiosFetch";
import { dateToString } from "../Dokter/AddData";

const ProfileHeader = ({
  profile,
  currentQueue,
  queueList,
  setCurrentQueue,
  finished,
  setFinished,
}) => {
  // console.log("finished", finished);
  const getTwoChar = (text) => {
    let nameArr = text.split(" ");

    try {
      if (nameArr.length > 1) {
        return nameArr[0].slice(0, 1) + nameArr[nameArr.length - 1].slice(0, 1);
      } else if (nameArr.length === 1) {
        return nameArr[0].slice(0, 1);
      } else {
        return "RM";
      }
    } catch {
      return "";
    }
  };

  // endpoint post needs to be
  // {
  //   "patient_id": "62c0df95252aedcf15f7cffc",
  //   "name": "",
  //   "complaint_date": "2022-07-04",
  //   "complaint": "",
  //   "doctor_name": "",
  //   "doctor_name_id": "",
  //   "room_name": "",
  //   "statusId": 0,
  //   "diagnose": "",
  //   "therapy": "",
  //   "examination": "",
  //   "recipes": []
  // }

  // originally

  // {
  //   "user_uid": "hLBarhzQpvRotyCW6w0DgfnY6tB2",
  //   "patient_id": "62c0df95252aedcf15f7cffc",
  //   "name": "Qubaisy",
  //   "registration_id": 0,
  //   "complaint_date": {
  //     "$date": 1656892800000
  //   },
  //   "complaint": "Keluhan Baru",
  //   "doctor_name": "Norma Dani",
  //   "doctor_name_id": "62c0de47252aedcf15f7cff7",
  //   "room_name": "Ruang Klinik Dokter Dani",
  //   "statusId": 0,
  //   "diagnose": "Diagnosa Baru",
  //   "therapy": "Terapi Baru",
  //   "examination": "Pemeriksaan Baru ",
  //   "recipes": [
  //     {
  //       "id": "62c16c72da3636ddc3ab9569",
  //       "label": "Amoxillin 500 MG, Antibiotik Merek Generic",
  //       "details": "3 x sehari harus habis"
  //     }
  //   ],
  //   "id": "62c232525f1c49c2fcb9cc1d"
  // }

  const handleChangeRekamMedis = () => {
    // console.log("currentQueue from handleChange", currentQueue);
    let dateFromTimeStamp = new Date(currentQueue?.complaint_date["$date"]);
    let dateQueue = dateToString(dateFromTimeStamp);
    let data = JSON.stringify({
      patient_id: currentQueue.patient_id,
      name: currentQueue?.name,
      complaint_date: dateQueue,
      complaint: currentQueue?.complaint,
      doctor_name: currentQueue?.doctor_name,
      doctor_name_id: currentQueue?.doctor_name_id,
      room_name: currentQueue?.room_name,
      statusId: 1,
      diagnose: currentQueue?.diagnose,
      therapy: currentQueue?.therapy,
      examination: currentQueue?.examination,
      recipes: currentQueue?.recipes,
    });

    let config = {
      url: `/rekammedis/${currentQueue?.id}`,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    AxiosFetch(config).then((res) =>
      // console.log("res.data from put", res.data)

      setCurrentQueue({ ...currentQueue, statusId: 1 })
    );
  };

  return (
    <>
      <div>
        <div>
          <img
            className="z-0 object-cover w-full h-16 lg:h-32"
            src={
              "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
            }
            alt=""
          />
        </div>
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center w-24 h-24 font-mono text-6xl font-bold text-white rounded-full bg-slate-400 sm:h-32 sm:w-32">
                {getTwoChar(profile.name || "")}
              </div>
            </div>
            <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
                <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {profile.name}
                </h1>
              </div>
              <div className="flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-y-0 sm:space-x-4">
                {finished === true && (
                  <button
                    type="button"
                    disabled={true}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-gray-300 rounded-md shadow-sm hover:text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    <CheckCircleIcon
                      className="w-5 h-5 mr-2 -ml-1"
                      aria-hidden="true"
                    />
                    <span>Selesai</span>
                  </button>
                )}
                {finished === false && (
                  <button
                    type="button"
                    disabled={queueList.length === 0}
                    onClick={(e) => {
                      handleChangeRekamMedis();
                      setFinished(true);
                    }}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-gray-300 rounded-md shadow-sm disabled:bg-indigo-100 hover:text-gray-700 disabled:text-gray-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    <DocumentAddIcon
                      className="w-5 h-5 mr-2 -ml-1"
                      aria-hidden="true"
                    />
                    <span>Selesaikan</span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
            <h1 className="text-2xl font-bold text-gray-900 truncate">
              {profile.name}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
