import { useState } from "react";

const SearchableDropdown = ({
  options,
  dropdownConfig,
  setDropdownConfig,
  selectePasienById,
  setSelectePasienById,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div>
      <div className="relative mt-1 w-96">
        <input
          onFocus={(e) => {
            setShowOptions(true);
          }}
          value={dropdownConfig.filterText}
          onChange={(e) =>
            setDropdownConfig({ ...dropdownConfig, filterText: e.target.value })
          }
          placeholder="Pilih / Ketik untuk Mencari"
          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        {showOptions ? (
          <ul
            className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            {options.map((opt, optIdx) => (
              <li
                className="relative py-2 pl-3 text-gray-900 cursor-pointer select-none pr-9"
                id={`listbox-option-${optIdx}`}
                onClick={() => {
                  setSelectePasienById(opt.value);
                  setShowOptions(false);
                  setDropdownConfig({ ...dropdownConfig, filterText: "" });
                }}
                key={optIdx}
              >
                <button>
                  <span className="block font-normal truncate">
                    {opt.label}
                  </span>

                  <span
                    className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                      selectePasienById === opt.value
                        ? "text-indigo-600"
                        : "text-white"
                    }`}
                  >
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchableDropdown;
