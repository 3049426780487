import React from "react";
import HistoryList from "./HistoryList";
import { historyHeaders } from "../../components/TableHeaders";

const QUERY_ADMIN = "/rekammedisbydate";
const Histories = () => {
  return (
    <HistoryList
      queryUrl={QUERY_ADMIN}
      headers={historyHeaders}
      mainTitle="Riwayat Rekam Medis"
      mainDetails="Download dalam file Excel (xlsx)"
    />
  );
};

export default Histories;
