import QueueComponent from "./QueueComponent";
import AxiosFetch from "../../utils/AxiosFetch";
import { dateToString } from "../Dokter/AddData";

export default function QueueBoard({
  queueList,
  setQueueList,
  setFinished,
  setCurrentQueue,
}) {
  const changeQueue = (e) => {
    let todayDate = new Date();
    let config = {
      method: "get",
      url: `/queue?doctor_id=${
        JSON.parse(localStorage.getItem("loginInfo")).dokter_id
      }&nrow=10&complaint_date=${dateToString(todayDate)}`,
      headers: {
        accept: "application/json",
      },
    };
    AxiosFetch(config)
      .then((res) => {
        setFinished(false);
        setQueueList(res.data.data);
        // setCurrentQueue(res.data.data[0]);
        e.preventDefault();
      })
      .catch((err) => {
        setCurrentQueue({});
        e.preventDefault();
      });
  };
  return (
    <div className="fixed w-screen2.5/10 h-screen6/10">
      <div className="items-center h-12 px-6 py-3 mx-8 mt-8 text-white bg-green-600 border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 ">
        Antrean
      </div>

      <QueueComponent
        queueList={queueList}
        setQueueList={setQueueList}
        setCurrentQueue={setCurrentQueue}
      />
      <div className="mx-8 my-8">
        <button
          type="button"
          onClick={(e) => {
            changeQueue(e);
          }}
          className="inline-flex items-center w-full h-24 px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Antrean Berikutnya
        </button>
      </div>
    </div>
  );
}
