import React from "react";
import { obatHeader } from "../../components/TableHeaders";
import Dokter from "../Dokter/Dokter";
const QUERY_OBAT = "/obat";

const Obat = () => {
  return (
    <Dokter
      queryUrl={QUERY_OBAT}
      headers={obatHeader}
      mainTitle="Obat"
      mainDetails="Masukkan Data Obat"
    />
  );
};

export default Obat;
