import React from "react";
import Dokter from "./Dokter";
import { dokterHeaders } from "../../components/TableHeaders";

const QUERY_DOKTER = "/dokter";
const DokterMain = () => {
  return (
    <Dokter
      queryUrl={QUERY_DOKTER}
      headers={dokterHeaders}
      mainTitle="Dokter"
      mainDetails="Masukkan Data Dokter"
    />
  );
};

export default DokterMain;
