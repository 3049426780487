import { ChevronRightIcon } from "@heroicons/react/solid";

// const queueList = [
//   {
//     _id: { $oid: "62842185fa0f248eb12dbe5f" },
//     user_uid: "9FGx2tbgAtRtf8fF009aFOk0Boq1",
//     patient_id: "6274ef04a6f3cee1c7e0e223",
//     name: "Qubaisy Andiyantama",
//     complaint_date: { $date: "2022-05-18T00:00:00.000Z" },
//     complaint: "bbb",
//     doctor_name: "Husna",
//     room_name: "Ruang Yagitu",
//     statusId: 0,
//     diagnose: "",
//     therapy: "",
//     examination: "",
//     recipes: [],
//     queue_no: 2,
//   },
//   {
//     _id: { $oid: "6287b6b79edec6ac39a323da" },
//     user_uid: "9FGx2tbgAtRtf8fF009aFOk0Boq1",
//     patient_id: "6284222cfa0f248eb12dbe60",
//     name: "Tejo Kusumo",
//     complaint_date: { $date: "2022-05-20T00:00:00.000Z" },
//     complaint: "- Sariawan \n- Pusing\n- Projek ga kelar-kelar",
//     doctor_name: "Norma Dani Risdiandita",
//     room_name: "Ruang Yagitu",
//     statusId: 0,
//     diagnose: "",
//     therapy: "",
//     examination: "",
//     recipes: [],
//     queue_no: 3,
//   },
// ];

export default function QueueComponent({
  queueList,
  setQueueList,
  setCurrentQueue,
}) {
  return (
    <div className="mx-8 mt-8 overflow-scroll bg-white shadow sm:rounded-md h-screen1/2">
      <ul className="divide-y divide-gray-200">
        {queueList.map((queue, queueId) => (
          <li key={queueId}>
            <div
              className="block cursor-pointer hover:bg-gray-50"
              onClick={() => {
                setCurrentQueue(queue);
              }}
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex items-center flex-1 min-w-0">
                  <div className="flex items-center justify-center font-mono text-lg font-bold text-white rounded-full bg-slate-400 h-14 w-14">
                    {String(queue.registration_id + 1).padStart(3, "0")}
                  </div>
                  <div className="flex-1 min-w-0 px-4 ">
                    <div>
                      <p className="text-sm font-medium text-indigo-600">
                        {queue.name}{" "}
                        {queueId === 0 ? " (Antrean Sekarang)" : ""}
                      </p>
                      <p className="flex items-center mt-2 text-sm text-gray-500">
                        <span className="truncate">{queue.complaint}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <ChevronRightIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
