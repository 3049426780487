import React, { useState } from "react";
import { dateToHumanString } from "../RekamMedis/RekamMedisList";
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/react/solid";
import Select from "react-select";

const GenericInput = ({
  inputLabel,
  inputType,
  currentQueue,
  setCurrentQueue,
  keyAccessor,
  obatSelection,
  setObatSelection,
  setFilterTextObat,
}) => {
  const [currentAddObat, setCurrentAddObat] = useState({});

  // if (typeof (currentQueue[keyAccessor] === "undefined")) {
  //   console.log("currentQueue", currentQueue);
  //   console.log("keyAccessor", keyAccessor);
  //   console.log(typeof "" === "undefined");
  //   console.log("currentQueue[keyAccessor]", currentQueue[keyAccessor] === "");
  //   return "";
  // }

  const handleAddObat = (currentQueue, currentAddObat) => {
    let newRecipes = currentQueue[keyAccessor];

    newRecipes.push(currentAddObat);

    setCurrentQueue({ ...currentQueue, [keyAccessor]: newRecipes });
  };
  if (inputType === "text") {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {inputLabel}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex max-w-lg rounded-md shadow-sm">
            <input
              type="text"
              name={inputLabel}
              id={inputLabel}
              value={currentQueue[keyAccessor]}
              // defaultValue={currentQueue[keyAccessor]}
              className="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    );
  } else if (inputType === "textarea") {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {inputLabel}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <textarea
            id={inputLabel}
            name={inputLabel}
            rows={3}
            onChange={(event) =>
              setCurrentQueue({
                ...currentQueue,
                [keyAccessor]: event.target.value,
              })
            }
            value={currentQueue[keyAccessor]}
            className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            defaultValue={currentQueue[keyAccessor]}
          />
        </div>
      </div>
    );
  } else if (inputType === "dropdown") {
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {inputLabel}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          {/* <select
            id={inputLabel}
            name={inputLabel}
            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm"
          >
            <option>United States</option>
            <option>Canada</option>
            <option>Mexico</option>
          </select> */}
          <Select
            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm"
            options={[{ value: "contoh value", label: "contoh label" }]}
            defaultValue={{ value: "contoh value", label: "contoh label" }}
            onChange={(e) => {
              // setFormValue({
              //   ...formValue,
              //   [header_.accessor]: e.label,
              //   [header_.accessor + "_id"]: e.value,
              // });
              console.log(e);
            }}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              input: (base) => ({
                ...base,
                "input:focus": {
                  boxShadow: "none",
                },
              }),
            }}
          />
        </div>
      </div>
    );
  } else if (inputType === "date") {
    let currentDate = "";
    if (typeof currentQueue[keyAccessor] !== "undefined") {
      currentDate = new Date(currentQueue[keyAccessor]["$date"]);
      currentDate = dateToHumanString(currentDate);
    }
    return (
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          {inputLabel}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="flex max-w-lg rounded-md shadow-sm">
            <div className="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              {currentDate}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (inputType === "recipes") {
    let recipeList = [];
    if (typeof currentQueue[keyAccessor] !== "undefined") {
      recipeList = currentQueue[keyAccessor];
    }
    return (
      <>
        {recipeList.map((recipe_, recipeIdx) => (
          <div
            key={recipeIdx}
            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
          >
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {recipeList.length > 0 && recipeIdx === 0 && inputLabel}
            </label>
            <div className="flex mt-1 space-x-4 sm:mt-0 sm:col-span-2">
              <div className="w-2/6 ml-3 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                {recipe_.label}
              </div>
              <div className="w-1/2 min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                {recipe_.details}
              </div>
            </div>
          </div>
        ))}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            {recipeList.length === 0 && inputLabel}
          </label>
          <div className="flex mt-1 space-x-4 sm:mt-0 sm:col-span-2">
            <Select
              className="w-2/6 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              options={obatSelection}
              defaultValue={obatSelection[0]}
              onChange={(e) => {
                setCurrentAddObat({
                  ...currentAddObat,
                  id: e.value,
                  label: e.label,
                });
              }}
              onInputChange={(textInput) => setFilterTextObat(textInput)}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                input: (base) => ({
                  ...base,
                  "input:focus": {
                    boxShadow: "none",
                  },
                }),
              }}
            />
            <input
              type="text"
              name={inputLabel}
              id={inputLabel}
              onChange={(e) =>
                setCurrentAddObat({
                  ...currentAddObat,
                  details: e.target.value,
                })
              }
              placeholder={"Keterangan Dosis"}
              className="w-1/2 min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              type="button"
              // onClick={setCurrentQueue({
              //   ...currentQueue,
              //   [keyAccessor]: [
              //     ...recipeList,
              //     { id: "contohid", label: "contohlabel" },
              //   ],
              // })}
              onClick={() => handleAddObat(currentQueue, currentAddObat)}
              className="placeholder-gray-300 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusSmIconSolid className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </>
    );
  } else {
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        {inputLabel}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="flex max-w-lg rounded-md shadow-sm">
          <input
            type="text"
            name={inputLabel}
            id={inputLabel}
            className="flex-1 block w-full min-w-0 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>;
  }
};

export default GenericInput;
