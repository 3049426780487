import { auth } from "../../../firebase";
import React, { useContext, useEffect, useState } from "react";
import AxiosFetch from "../../../utils/AxiosFetch";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  const [loading, setLoading] = useState(true);
  function signup(email, password) {
    // displayName
    let user = auth.createUserWithEmailAndPassword(email, password);
    return user;
  }

  function login(email, password, accountType) {
    let config = {
      method: "get",
      url: `/userbyemail/?email=${email}`,
      headers: {
        accept: "application/json",
      },
    };

    AxiosFetch(config).then((res) => {
      if (res.data.account_types.includes(accountType)) {
        let storageInfo = {};
        if (res.data.account_types.includes("doctor")) {
          storageInfo = { ...storageInfo, is_doctor: true };
          storageInfo = { ...storageInfo, dokter_id: res.data.dokter_id };
        }

        if (res.data.account_types.includes("admin")) {
          storageInfo = { ...storageInfo, is_admin: true };
          storageInfo = { ...storageInfo, user_uid: res.data.user_uid };
        }

        localStorage.setItem("loginInfo", JSON.stringify(storageInfo));
        return auth.signInWithEmailAndPassword(email, password);
      } else {
        return null;
      }
    });
  }

  function logout() {
    localStorage.removeItem("loginInfo");
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
