/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";
import AxiosFetch from "../../utils/AxiosFetch";
import { SearchIcon, TrashIcon } from "@heroicons/react/outline";
import AddData from "./AddData";
import EditData from "./EditData";
import Pagination from "./Pagination";
import LocalNavbar from "../RekamMedis/LocalNavbar";

const PASIEN_TITLE = "Pasien";

export default function Dokter({ queryUrl, headers, mainTitle, mainDetails }) {
  const [addModalOpen, setAddModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [inputEditValue, setInputEditValue] = useState({});

  const { currentUser } = useAuth();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   let modalOpen = [];
  //   for (let i = 0; i < data.length; i++) {
  //     modalOpen.push(false);
  //   }
  // }, [data]);

  const [paginationSetting, setPaginationSetting] = useState({
    limit: 5,
    filter_text: "",
  });

  const [pagination, setPagination] = useState({
    page: 0,
    totalPage: 0,
    totalResults: 0,
  });

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${queryUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
      headers: {
        accept: "application/json",
      },
    };

    AxiosFetch(config).then((res) => {
      if (mainTitle === PASIEN_TITLE) {
        let tempData = [];
        for (let i = 0; i < res.data.data.length; i++) {
          tempData.push({
            ...res.data.data[i],
            registration_date: new Date(res.data.data[i].registration_date),

            birthday: new Date(res.data.data[i].birthday),
          });
        }
        setData(tempData);
        setLoading(false);
      } else {
        setData(res.data.data);
        setLoading(false);
      }

      setPagination({
        ...pagination,
        totalPage: res.data.totalPage,
        totalResults: res.data.totalResults,
      });
    });
  }, []);

  useEffect(() => {
    let config = {
      method: "get",
      url: `${queryUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
      headers: {
        accept: "application/json",
      },
    };

    AxiosFetch(config).then((res) => {
      if (mainTitle === PASIEN_TITLE) {
        let tempData = [];
        for (let i = 0; i < res.data.data.length; i++) {
          tempData.push({
            ...res.data.data[i],
            registration_date: new Date(res.data.data[i].registration_date),

            birthday: new Date(res.data.data[i].birthday),
          });
        }
        setData(tempData);
      } else {
        setData(res.data.data);
      }
    });
  }, [pagination]);

  useEffect(() => {
    const fetchData = setTimeout(() => {
      let config = {
        method: "get",
        url: `${queryUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
        headers: {
          accept: "application/json",
        },
      };

      AxiosFetch(config).then((res) => {
        if (mainTitle === PASIEN_TITLE) {
          let tempData = [];
          for (let i = 0; i < res.data.data.length; i++) {
            tempData.push({
              ...res.data.data[i],
              registration_date: new Date(res.data.data[i].registration_date),

              birthday: new Date(res.data.data[i].birthday),
            });
          }
          setData(tempData);
        } else {
          setData(res.data.data);
        }
        setPagination({
          page: 0,
          totalPage: res.data.totalPage,
          totalResults: res.data.totalResults,
        });
      });
    }, 1500);

    return () => clearTimeout(fetchData);
  }, [paginationSetting.filter_text]);

  return (
    <>
      <LocalNavbar />
      <div className="w-full mt-16 pt-4 mx-4 lg:mx-0 lg:w-3/4 sm:px-6 lg:px-8 lg:ml-screen2.5/10">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{mainTitle}</h1>
            <p className="mt-2 text-sm text-gray-700">{mainDetails}</p>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => setAddModalOpen(true)}
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {`Tambah ${mainTitle}`}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-start mt-8 space-x-4 align-middle">
          <input
            type="search"
            onChange={(event) =>
              setPaginationSetting({
                ...paginationSetting,
                filter_text: event.target.value,
              })
            }
            placeholder="Ketik Nama"
            className="w-64 border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div className="inline-flex items-ce">
            <SearchIcon className="inline-flex w-6 h-6" />
          </div>
        </div>

        <div className="mt-8 -mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300 table-fixed">
            <thead className="bg-gray-50">
              <tr>
                {headers.map((header, headerIdx) => {
                  if (headerIdx === 0) {
                    return (
                      <th
                        key={headerIdx}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 ${
                          header.css || ""
                        }`}
                      >
                        {header.Header}
                      </th>
                    );
                  } else if (headerIdx < 5) {
                    return (
                      <th
                        key={headerIdx}
                        scope="col"
                        className={`px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ${
                          header.css || ""
                        }`}
                      >
                        {header.Header}
                      </th>
                    );
                  }
                })}

                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Ubah</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((person, personIdx) => (
                <tr key={personIdx}>
                  {headers.map((header, headerIdx) => {
                    if (headerIdx === 0) {
                      return (
                        <td
                          key={headerIdx}
                          className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-900  sm:pl-6 ${
                            header.css || ""
                          }`}
                        >
                          {person[header.accessor]}
                        </td>
                      );
                    } else if (headerIdx < 5) {
                      return (
                        <td
                          key={headerIdx}
                          className={`px-3 py-4 text-sm text-gray-500  ${header.css}`}
                        >
                          {person[header.accessor]}
                        </td>
                      );
                    }
                  })}

                  <td className="relative flex items-center justify-between py-4 pl-3 pr-4 text-sm font-medium text-right sm:pr-6">
                    <button
                      onClick={() => {
                        setInputEditValue(person);
                        setEditModalOpen(true);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Ubah<span className="sr-only">, {person.name}</span>
                    </button>
                    <button
                      onClick={() => {
                        let config = {
                          method: "delete",
                          url: `${queryUrl}/?id=${person.id}`,
                          headers: {
                            accept: "application/json",
                          },
                        };

                        AxiosFetch(config).then((res) => {
                          let getConfig = {
                            method: "get",
                            url: `${queryUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
                            headers: {
                              accept: "application/json",
                            },
                          };

                          AxiosFetch(getConfig).then((res) => {
                            if (mainTitle === PASIEN_TITLE) {
                              let tempData = [];
                              for (let i = 0; i < res.data.data.length; i++) {
                                tempData.push({
                                  ...res.data.data[i],
                                  registration_date: new Date(
                                    res.data.data[i].registration_date
                                  ),

                                  birthday: new Date(res.data.data[i].birthday),
                                });
                              }
                              setData(tempData);
                            } else {
                              setData(res.data.data);
                            }
                            setPagination({
                              ...pagination,
                              totalPage: res.data.totalPage,
                              totalResults: res.data.totalResults,
                            });
                          });
                        });
                      }}
                    >
                      <TrashIcon className="w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            pagination={pagination}
            paginationSetting={paginationSetting}
            setPagination={setPagination}
          />
        </div>
        <AddData
          addModalOpen={addModalOpen}
          setAddModalOpen={setAddModalOpen}
          headers={headers}
          addUrl={queryUrl}
          getUrl={queryUrl}
          pagination={pagination}
          setPagination={setPagination}
          setData={setData}
          mainTitle={mainTitle}
          mainDetails={mainDetails}
          paginationSetting={paginationSetting}
        />

        <EditData
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          headers={headers}
          editUrl={queryUrl}
          getUrl={queryUrl}
          pagination={pagination}
          setData={setData}
          inputValue={inputEditValue}
          paginationSetting={paginationSetting}
          mainTitle={mainTitle}
          mainDetails={mainDetails}
        />
      </div>
    </>
  );
}
