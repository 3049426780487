import React from "react";
import Select from "react-select";
import CustomDatePicker from "../../components/CustomDatePicker";

const InputElement = ({
  header_,
  headerIdx,
  selectionValue,
  formValue,
  setFormValue,
}) => {
  if (header_.hidden === true) {
    return <></>;
  }

  if (header_.inputType === "dropdown") {
    return (
      <div
        key={headerIdx}
        className="flex items-center justify-start w-full my-3 space-x-2"
      >
        <div className="w-40 text-sm font-medium text-gray-700">
          {header_.Header}
        </div>
        <Select
          className="text-sm w-80"
          options={header_.options}
          defaultValue={selectionValue[header_.accessor]}
          onChange={(e) => {
            setFormValue({
              ...formValue,
              [header_.accessor]: e.label,
              [header_.accessor + "_id"]: e.value,
            });
          }}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            input: (base) => ({
              ...base,
              "input:focus": {
                boxShadow: "none",
              },
            }),
          }}
        />
      </div>
    );
  } else if (header_.inputType === "datepicker") {
    return (
      <div
        key={headerIdx}
        className="flex items-center justify-start w-full my-3 space-x-2"
      >
        <div className="w-40 text-sm font-medium text-gray-700">
          {header_.Header}
        </div>
        <CustomDatePicker
          formValue={formValue}
          setFormValue={setFormValue}
          paramToChange={header_.accessor}
          initialDate={selectionValue[header_.accessor]}
        />
      </div>
    );
  } else if (header_.inputType === "textarea") {
    return (
      <div
        key={headerIdx}
        className="flex items-center justify-start w-full my-3 space-x-2"
      >
        <div className="w-40 text-sm font-medium text-gray-700">
          {header_.Header}
        </div>
        <textarea
          disabled={header_.inputDisabled === true}
          autoComplete={header_.accessor}
          className="form-control block w-80 h-32 px-3 py-1.5 text-base font-normal  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition  ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          pattern={header_.inputType === "number" ? "d*" : null}
          onChange={(e) => {
            if (header_.inputType === "number") {
              setFormValue({
                ...formValue,
                [header_.accessor]: parseInt(e.target.value),
              });
            } else {
              setFormValue({
                ...formValue,
                [header_.accessor]: e.target.value,
              });
            }
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        key={headerIdx}
        className="flex items-center justify-start w-full my-3 space-x-2"
      >
        <div className="w-40 text-sm font-medium text-gray-700">
          {header_.Header}
        </div>
        <input
          defaultValue={formValue[header_.accessor]}
          disabled={header_.inputDisabled === true}
          autoComplete={header_.accessor}
          className="flex-grow block border-gray-300 rounded-lg disabled:bg-gray-200 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-80 max-w-80"
          type={header_.inputType || "text"}
          pattern={header_.inputType === "number" ? "d*" : null}
          onChange={(e) => {
            if (header_.inputType === "number") {
              setFormValue({
                ...formValue,
                [header_.accessor]: parseInt(e.target.value),
              });
            } else {
              setFormValue({
                ...formValue,
                [header_.accessor]: e.target.value,
              });
            }
          }}
        />
      </div>
    );
  }
};

export default InputElement;
