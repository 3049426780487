import { forwardRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { format } from "date-fns";

const CustomDatePicker = ({
  formValue,
  setFormValue,
  paramToChange,
  initialDate,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    // console.log("initialDate", initialDate);
    if (typeof initialDate !== "undefined") {
      setFormValue({ ...formValue, [paramToChange]: initialDate });
      setStartDate(initialDate);
    } else {
      setFormValue({ ...formValue, [paramToChange]: startDate });
    }
  }, []);

  useEffect(() => {
    setFormValue({ ...formValue, [paramToChange]: startDate });
  }, [startDate]);

  return (
    <div className="relative w-80">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        dateFormat="dd-MM-yyyy"
        startDate={startDate}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-left"
        // customInput={<TextInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <span className="text-lg text-gray-700">
              {format(date, "MMMM yyyy")}
            </span>

            <div className="space-x-2">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`
                                            ${
                                              prevMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`
                                            ${
                                              nextMonthButtonDisabled &&
                                              "cursor-not-allowed opacity-50"
                                            }
                                            inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500
                                        `}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CustomDatePicker;

// const TextInput = forwardRef(({value, onChange}, ref) => (
//   <input
//   onChange={onChange}
//   ref={ref}
//   type='text'
//   className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"

//   />
// ));
// const ButtonInput = forwardRef(({ value, onClick }, ref) => (
//   <button
//     onClick={onClick}
//     ref={ref}
//     type="button"
//     className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
//   >
//     {format(new Date(value), "dd MMMM yyyy")}
//   </button>
// ));
