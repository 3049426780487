import { antreanHeader } from "../../components/TableHeaders";
import GenericInput from "./GenericInput";

// {
//   "currentQueue": {
//     "user_uid": "hLBarhzQpvRotyCW6w0DgfnY6tB2",
//     "patient_id": "62c0df4b252aedcf15f7cffa",
//     "name": "Teja Kusuma",
//     "registration_id": 0,
//     "complaint_date": {
//       "$date": 1656806400000
//     },
//     "complaint": "Pusing dan Proyek Ga kelar-kelar",
//     "doctor_name": "Norma Dani",
//     "doctor_name_id": "62c0de47252aedcf15f7cff7",
//     "room_name": "Ruang Klinik Dokter Dani",
//     "statusId": 0,
//     "diagnose": "",
//     "therapy": "",
//     "examination": "",
//     "recipes": [],
//     "id": "62c0df66252aedcf15f7cffb"
//   }
// }

export default function RekamMedisQueue({
  currentQueue,
  setCurrentQueue,
  obatSelection,
  setObatSelection,
  setFilterTextObat,
}) {
  return (
    <div className="mx-8 mb-32">
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
              {currentQueue
                ? antreanHeader.map((queueElem, queueElemIdx) => (
                    <GenericInput
                      key={`queueElem ${queueElemIdx}`}
                      inputLabel={queueElem.Header}
                      inputType={queueElem.inputType || "text"}
                      currentQueue={currentQueue}
                      setCurrentQueue={setCurrentQueue}
                      keyAccessor={queueElem.accessor}
                      obatSelection={obatSelection}
                      setObatSelection={setObatSelection}
                      setFilterTextObat={setFilterTextObat}
                    />
                  ))
                : "Belom ada Antrean... "}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
