/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import cloneDeep from "lodash/cloneDeep";
import AxiosFetch from "../../utils/AxiosFetch";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";
import InputElement from "./InputElement";
import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import copy from "copy-to-clipboard";

const dateToString = (inputDate) => {
  let month = inputDate.getMonth();
  let day = inputDate.getDate();
  let year = inputDate.getFullYear();
  return (
    String(year) +
    "-" +
    String(month + 1).padStart(2, "0") +
    "-" +
    String(day).padStart(2, "0")
  );
};

const generatePassword = () => {
  let alphanumeric = "abcdefghijklmnopqrstuvwxyz01234567890";
  let outString = "";
  for (let j = 0; j < 8; j++) {
    let randint = Math.floor(Math.random() * alphanumeric.length);
    outString = outString.concat(alphanumeric[randint]);
  }

  return outString;
};

const PASIEN_TITLE = "Pasien";
const DOKTER_TITLE = "Dokter";
export default function AddData({
  addModalOpen,
  setAddModalOpen,
  headers,
  addUrl,
  getUrl,
  pagination,
  setPagination,
  setData,
  mainTitle,
  mainDetails,
  paginationSetting,
}) {
  const { currentUser } = useAuth();
  const cancelButtonRef = useRef(null);
  const [formValue, setFormValue] = useState({});
  const [selectionValue, setSelectionValue] = useState({});
  const [inputStatus, setInputStatus] = useState(false);
  const [emailDokter, setEmailDokter] = useState("");
  const [passwordDokter, setPasswordDokter] = useState("");

  const [dokterAccInfo, setDokterAccInfo] = useState("");

  useEffect(() => {
    setDokterAccInfo(`Email: ${emailDokter}
Password: ${passwordDokter}`);
  }, [emailDokter, passwordDokter]);

  // initialise formValue

  const initialiseFormValue = () => {
    let temp = {};
    let tempSelection = {};
    for (let i = 0; i < headers.length; i++) {
      if (typeof headers[i].default !== "undefined") {
        if (headers[i].inputType === "dropdown") {
          temp = {
            ...temp,
            [headers[i].accessor]: headers[i].default.label,
            [headers[i].accessor + "_id"]: headers[i].default.value,
          };
        } else {
          temp = { ...temp, [headers[i].accessor]: headers[i].default };
        }

        tempSelection = {
          ...tempSelection,
          [headers[i].accessor]: headers[i].default,
        };
      }
    }
    setSelectionValue(tempSelection);
    setFormValue(temp);
  };

  useEffect(() => {
    initialiseFormValue();
  }, [headers]);
  const handleRegister = () => {
    let formattedFormValue = cloneDeep(formValue);

    for (let i = 0; i < headers.length; i++) {
      if (headers[i].inputType === "datepicker") {
        formattedFormValue = {
          ...formattedFormValue,
          [headers[i].accessor]: dateToString(
            formattedFormValue[headers[i].accessor]
          ),
        };
      }
    }

    let generatedPassword = generatePassword();

    formattedFormValue = {
      ...formattedFormValue,
      password: generatedPassword,
    };

    setEmailDokter(formattedFormValue?.email);
    setPasswordDokter(generatedPassword);

    // http://207.180.226.31:8090/dokter?user_uid=9FGx2tbgAtRtf8fF009aFOk0Boq1

    let data = JSON.stringify(formattedFormValue);
    let config = {
      method: "post",
      url: `${addUrl}/${currentUser?.uid || ""}`,
      headers: {
        accept: "application/json",
      },
      data: data,
    };

    AxiosFetch(config)
      .then((res) => {
        let getConfig = {
          method: "get",
          url: `${getUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
          headers: {
            accept: "application/json",
          },
        };

        AxiosFetch(getConfig)
          .then((res) => {
            if (mainTitle === PASIEN_TITLE) {
              let tempData = [];
              for (let i = 0; i < res.data.data.length; i++) {
                tempData.push({
                  ...res.data.data[i],
                  registration_date: new Date(
                    res.data.data[i].registration_date
                  ),

                  birthday: new Date(res.data.data[i].birthday),
                });
              }
              setData(tempData);
            } else {
              setData(res.data.data);
            }
            setPagination({
              ...pagination,
              totalPage: res.data.totalPage,
              totalResults: res.data.totalResults,
            });
            // setFormValue({});
            initialiseFormValue();
            setInputStatus(true);
            setEmailDokter(formattedFormValue?.email);
            setPasswordDokter(generatedPassword);
          })
          .catch((err) => {
            console.log("get err", err);
            // console.log("formattedFormValue", formattedFormValue);
          });
      })
      .catch((err) => {
        console.log("err", err);
        // console.log("formattedFormValue", formattedFormValue);
      });
  };

  return (
    <Transition.Root show={addModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          setAddModalOpen(e);
          setInputStatus(e);
          setEmailDokter("");
          setPasswordDokter("");
        }}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:min-w-96 sm:w-full sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
              <div>
                {inputStatus ? (
                  <>
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                      <CheckIcon
                        className="w-6 h-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Berhasil Ditambahkan
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {`${mainTitle} berhasil ditambahkan, Click Kembali ke Dashboard untuk melanjutkan`}
                        </p>

                        {mainTitle === DOKTER_TITLE ? (
                          <>
                            <div className="mt-6 text-sm text-gray-900">
                              Berikut kredensial login untuk dokter yang
                              ditambahkan. Salin dan kirimkan ke dokter anda
                            </div>
                            <div className="flex justify-center mt-4 space-x-0">
                              <div className="flex flex-col ">
                                <div className="px-4 py-2 text-left rounded-tl-lg bg-slate-100">
                                  {`Email: ${emailDokter}`}
                                </div>
                                <div className="px-4 py-2 text-left rounded-bl-lg bg-slate-100">
                                  {`Password: ${passwordDokter}`}
                                </div>
                              </div>
                              <button
                                className="px-4 border-2 rounded-r-lg btn btn-info"
                                onClick={() => copy(dokterAccInfo)}
                              >
                                <ClipboardIcon
                                  className="w-6 h-6 text-green-600"
                                  aria-hidden="true"
                                ></ClipboardIcon>
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        onClick={() => {
                          setAddModalOpen(false);
                          setInputStatus(false);

                          // setEmailDokter("");
                          // setPasswordDokter("");
                        }}
                      >
                        Kembali ke Dashboard
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-medium leading-6 text-gray-900"
                      >
                        {`Daftar ${mainTitle}`}
                      </Dialog.Title>
                      <div>
                        <p className="mt-1 text-sm text-gray-500">
                          {`${mainDetails} dengan tepat`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start my-4">
                      {headers.map((header_, headerIdx) => (
                        <InputElement
                          key={headerIdx}
                          header_={header_}
                          headerIdx={headerIdx}
                          selectionValue={selectionValue}
                          formValue={formValue}
                          setFormValue={setFormValue}
                        />
                      ))}
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          handleRegister();
                        }}
                      >
                        Daftar
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setAddModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { dateToString };
