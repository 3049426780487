// import { Fragment, useState } from "react";
// import { Dialog, Transition } from "@headlessui/react";

import { useEffect, useState } from "react";
import LocalNavbar from "./LocalNavbar";
import Profile from "./Profile";
import ProfileHeader from "./ProfileHeader";
// import TeamMemberList from "./TeamMemberList";
import { pasienHeaders } from "../../components/TableHeaders";
import { dateToString } from "../Dokter/AddData";
import RekamMedisList from "./RekamMedisList";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const mainTitle = "Rekam Medis";
const mainDetails = "Masukkan rekam medis untuk pasien";

export default function RekamMedis() {
  const [profilPasien, setProfilPasien] = useState({
    name: "Ricardo Cooper",
    imageUrl:
      "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
    coverImageUrl:
      "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    about: `
    <p>Profil</p>
  `,
    fields: {
      // Phone: "(555) 123-4567",
      // Email: "ricardocooper@example.com",
      // Title: "Senior Front-End Developer",
      // Team: "Product Development",
      // Location: "San Francisco",
      // Sits: "Oasis, 4th floor",
      // Salary: "$145,000",
      // Birthday: "June 8, 1990",
      // Contoh: "contoh lagi",
      // Yagitu: "gitu lagi ",
    },
  });

  const [data, setData] = useState([]);

  const tabs = [
    { name: "Profil Pasien", href: "profil", current: true },
    { name: "Riwayat Rekam Medis", href: "rekam", current: false },
    // { name: "Recognition", href: "#", current: false },
  ];

  const [tabSelected, setTabSelected] = useState([
    { name: "Profil Pasien", href: "profil", current: true },
    { name: "Riwayat Rekam Medis", href: "rekam", current: false },
    // { name: "Recognition", href: "#", current: false },
  ]);

  useEffect(() => {
    let temp = {};
    let profileName = "";
    for (let i = 0; i < pasienHeaders.length; i++) {
      if (pasienHeaders[i].inputType === "dropdown") {
        temp = {
          ...temp,
          [pasienHeaders[i].Header]: pasienHeaders[i].default.label,
        };
      } else if (pasienHeaders[i].inputType === "datepicker") {
        temp = {
          ...temp,
          [pasienHeaders[i].Header]: dateToString(pasienHeaders[i].default),
        };
      } else if (pasienHeaders[i].accessor === "name") {
        temp = { ...temp, [pasienHeaders[i].Header]: pasienHeaders[i].default };
        profileName = pasienHeaders[i].default;
      } else {
        temp = { ...temp, [pasienHeaders[i].Header]: pasienHeaders[i].default };
      }
    }

    setProfilPasien({ ...profilPasien, fields: temp, name: profileName });
  }, [pasienHeaders]);

  const [paginationSetting, setPaginationSetting] = useState({
    limit: 5,
    filter_text: "",
  });

  const [pagination, setPagination] = useState({
    page: 0,
    totalPage: 0,
    totalResults: 0,
  });

  return (
    <>
      <LocalNavbar profile={profilPasien} setProfile={setProfilPasien} />
      <div className="flex mt-16 flex-col flex-1 min-w-0 overflow-hidden lg:ml-screen2.5/10">
        <div className="relative flex flex-1 overflow-hidden">
          <main className="relative flex-1 overflow-y-auto focus:outline-none xl:order-last">
            <article>
              {/* Profile header */}
              <ProfileHeader
                profile={profilPasien}
                pagination={pagination}
                setPagination={setPagination}
                mainTitle={mainTitle}
                mainDetails={mainDetails}
                setData={setData}
                paginationSetting={paginationSetting}
              />
              {/* Tabs */}
              <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="border-b border-gray-200">
                  <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                    <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                      {tabs.map((tab, tabIdx) => (
                        <button
                          key={tab.name}
                          href={tab.href}
                          onClick={() => {
                            let temp = [];
                            for (let i = 0; i < tabs.length; i++) {
                              if (tabs[i].href === tab.href) {
                                temp.push({ ...tab, current: true });
                              } else {
                                temp.push({ ...tab, current: false });
                              }
                            }

                            setTabSelected(temp);
                          }}
                          className={classNames(
                            tabSelected[tabIdx].current
                              ? "border-pink-500 text-gray-900"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                          )}
                          aria-current={tab.current ? "page" : undefined}
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>

              {/* Profil list */}
              {tabSelected[0].current ? <Profile profile={profilPasien} /> : ""}
              {tabSelected[1].current ? (
                <RekamMedisList profile={profilPasien} />
              ) : (
                ""
              )}

              {/* <TeamMemberList team={team} /> */}
            </article>
          </main>
        </div>
      </div>
    </>
  );
}
