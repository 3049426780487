/* This example requires Tailwind CSS v2.0+ */
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  LibraryIcon,
} from "@heroicons/react/outline";

import { useEffect, useState } from "react";

import { NavLink as Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SidebarNav() {
  const [navigation, setNavigation] = useState([
    { name: "Dashboard", icon: HomeIcon, href: "/dashboard", current: true },
    {
      name: "Admin Pencatat",
      icon: LibraryIcon,
      href: "/admin",
      count: 4,
      current: false,
    },
    {
      name: "Dokter",
      icon: UsersIcon,
      href: "/dokter",
      count: 3,
      current: false,
    },
    {
      name: "Pasien",
      icon: FolderIcon,
      href: "/pasien",
      count: 4,
      current: false,
    },
    { name: "Ruang", icon: CalendarIcon, href: "/ruang", current: false },
    { name: "Obat", icon: InboxIcon, href: "/obat", count: 12, current: false },
    {
      name: "Rekam Medis",
      icon: ChartBarIcon,
      href: "/rekammedis",
      current: false,
    },
    {
      name: "Antrean Dokter",
      icon: FolderIcon,
      href: "/antrean",
      count: 2,
      current: false,
    },
    {
      name: "Rekapan Rekam Medis",
      icon: FolderIcon,
      href: "history",
      count: 1,
      current: false,
    },
  ]);

  useEffect(() => {
    let updatedNav = [];
    for (let i = 0; i < navigation.length; i++) {
      if (navigation[i].href === window.location.pathname) {
        updatedNav.push({ ...navigation[i], current: true });
      } else {
        updatedNav.push({ ...navigation[i], current: false });
      }
      setNavigation(updatedNav);
    }
  }, []);

  return (
    <div className="fixed hidden w-screen2.5/10 h-screen lg:block">
      <div className="flex flex-col flex-1 h-screen min-h-0 bg-indigo-700">
        <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="w-auto h-8"
              src="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
              alt="Workflow"
            />
          </div>
          <nav className="flex-1 px-2 mt-5 space-y-1" aria-label="Sidebar">
            {navigation.map((item) => (
              <Link
                key={item.name}
                // href={item.href}
                onClick={() => {
                  let updatedNav = [];
                  for (let i = 0; i < navigation.length; i++) {
                    if (navigation[i].name === item.name) {
                      updatedNav.push({ ...navigation[i], current: true });
                    } else {
                      updatedNav.push({ ...navigation[i], current: false });
                    }
                  }

                  setNavigation(updatedNav);
                }}
                to={item.href}
                className={classNames(
                  item.current
                    ? "bg-indigo-800 text-white"
                    : "text-indigo-100 hover:bg-indigo-600 hover:bg-opacity-75",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                )}
              >
                <item.icon
                  className="flex-shrink-0 w-6 h-6 mr-3 text-indigo-300"
                  aria-hidden="true"
                />
                <span className="flex-1">{item.name}</span>
                {item.count ? (
                  <span
                    className={classNames(
                      item.current ? "bg-indigo-600" : "bg-indigo-800",
                      "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                    )}
                  >
                    {item.count}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
        <div className="flex flex-shrink-0 p-4 border-t border-indigo-800">
          <button className="flex-shrink-0 block w-full group">
            <div className="flex items-center">
              <div>
                <img
                  className="inline-block rounded-full h-9 w-9"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">Tom Cook</p>
                <p className="text-xs font-medium text-indigo-200 group-hover:text-white">
                  View profile
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
