import { useEffect, useState } from "react";
import Select from "react-select";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";
import AxiosFetch from "../../utils/AxiosFetch";
import { pasienHeaders } from "../../components/TableHeaders";
import { dateToString } from "../Dokter/AddData";
import ProfileDropdown from "./ProfileDropdown";
import { useLocation } from "react-router-dom";
import SearchableDropdown from "../../components/SearchableDropdown";
import useLocalStorage from "use-local-storage";

export default function LocalNavbar({ profile, setProfile }) {
  const location = useLocation();

  const [options, setOptions] = useState([]);
  const [adminOptions, setAdminOptions] = useState([]);
  const [chosenAdmin, setChosenAdmin] = useLocalStorage("adminname", {
    value: "",
    label: "",
  });
  const { currentUser } = useAuth();

  const [dropdownConfig, setDropdownConfig] = useState({
    limit: 10,
    filterText: "",
  });

  const [selectePasienById, setSelectePasienById] = useState("");

  const updateProfile = () => {
    if (selectePasienById !== "") {
      let config = {
        method: "get",
        url: `/rekammedis/profile?uid=${selectePasienById}`,
        accept: "application/json",
      };

      AxiosFetch(config).then((res) => {
        let temp = {};
        let profileName = "";
        for (let i = 0; i < pasienHeaders.length; i++) {
          if (pasienHeaders[i].inputType === "dropdown") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] ||
                pasienHeaders[i].default.label,
            };
          } else if (pasienHeaders[i].inputType === "datepicker") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]: dateToString(
                new Date(res.data[pasienHeaders[i].accessor]) ||
                  pasienHeaders[i].default
              ),
            };
          } else if (pasienHeaders[i].accessor === "name") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default,
            };

            profileName =
              res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default;
          } else {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default,
            };
          }
        }
        setProfile({
          ...profile,
          fields: temp,
          name: profileName,
          patient_id: selectePasienById,
        });
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/rekammedis") {
      updateProfile();
    }
  }, [selectePasienById]);

  const updateOptions = () => {
    let config = {
      method: "get",
      url: `/rekammedis/selection?user_uid=${currentUser?.uid}&limit=${
        dropdownConfig.limit || 10
      }&filter_text=${dropdownConfig.filterText || ""}`,
      accept: "application/json",
    };

    AxiosFetch(config)
      .then((res) => {
        setOptions(res.data);

        if (res.data.length > 0 && selectePasienById.length === 0) {
          setSelectePasienById(res.data[0].value);
        }
      })
      .catch((err) => setOptions([]));
  };

  const updateAdminOptions = () => {
    let config = {
      method: "get",
      url: `/admin/selection?user_uid=${currentUser?.uid}&limit=${
        dropdownConfig.limit || 10
      }&filter_text=${dropdownConfig.filterText || ""}`,
      accept: "application/json",
    };

    AxiosFetch(config)
      .then((res) => {
        setAdminOptions(res.data);
        if (res.data.length > 0 && chosenAdmin.length === 0) {
          setChosenAdmin(res.data[0].label);
        }
      })
      .catch((err) => setAdminOptions([]));
  };

  useEffect(() => {
    updateOptions();
    updateAdminOptions();
  }, []);

  useEffect(() => {
    updateOptions();
  }, [dropdownConfig]);

  return (
    <nav className="fixed lg:ml-screen2.5/10 z-10 w-full lg:w-screen7.5/10">
      <div className="z-10 flex items-center justify-between h-16 pr-8 bg-white shadow-md">
        <div className="flex items-center justify-start space-x-4">
          {location.pathname === "/rekammedis" ? (
            <div className="ml-4 text-sm font-medium text-gray-700">
              Cari Rekam Medis Pasien
            </div>
          ) : (
            <>
              <div className="ml-8 text-sm font-medium text-gray-700">
                Admin:
              </div>
              <Select
                className="z-10 text-sm w-80 "
                options={adminOptions}
                // onInputChange={(e) => updateOptions(e)}
                // setDropdownConfig({ ...dropdownConfig, filterText: e });
                onChange={(e) => {
                  // setSelectePasienById(e.value);
                  setChosenAdmin(e);
                }}
                menuPortalTarget={document.body}
                defaultValue={chosenAdmin}
                // onChange={(e) => {
                //   setOptions()
                // }}
                placeholder="Select / Type to Search"
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
              />{" "}
            </>
          )}
          {location.pathname === "/rekammedis" ? (
            <>
              <SearchableDropdown
                options={options}
                dropdownConfig={dropdownConfig}
                setDropdownConfig={setDropdownConfig}
                selectePasienById={selectePasienById}
                setSelectePasienById={setSelectePasienById}
              />
              {/* <Select
                className="z-10 text-sm w-80 "
                options={options}
                onInputChange={(e) => updateOptions(e)}
                // setDropdownConfig({ ...dropdownConfig, filterText: e });
                onChange={(e) => {
                  setSelectePasienById(e.value);
                }}
                menuPortalTarget={document.body}
                defaultValue={options[0]}
                // onChange={(e) => {
                //   setOptions()
                // }}
                placeholder="Select / Type to Search"
                styles={{
                  input: (base) => ({
                    ...base,
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 10 }),
                }}
              /> */}
            </>
          ) : (
            ""
          )}
        </div>
        <ProfileDropdown />
      </div>
    </nav>
  );
}
