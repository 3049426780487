import { useState, useEffect } from "react";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import Pagination from "../Dokter/Pagination";
import AxiosFetch from "../../utils/AxiosFetch";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";

const monthName = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const dayOfWeekName = [
  "Minggu",
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jum'at",
  "Sabtu",
];

const dateToHumanString = (inputDate) => {
  let month = inputDate.getMonth();
  let day = inputDate.getDate();
  let dayOfWeek = inputDate.getDay();
  let year = inputDate.getFullYear();

  return `${dayOfWeekName[dayOfWeek]}, ${String(day)} ${
    monthName[month]
  } ${String(year)}`;
};

export { dateToHumanString };

// const candidates = [
//   {
//     name: "Alfian",
//     complaint_date: dateToHumanString(new Date()),
//     complaint_datetime: new Date(),
//     complaint: "Sakit Perut dan Gatal gatal di bagian pelupuk mata",
//     statusId: 0,
//   },
//   {
//     name: "Richard Branson",
//     complaint_date: dateToHumanString(new Date()),
//     complaint_datetime: new Date(),
//     complaint: "Sakit Kepala, hidung tersumbat dan sariawan",
//     statusId: 1,
//   },

// ];

export default function RekamMedisList({ profile }) {
  // console.log("profile", profile.patient_id);
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [rekamMedisList, setRekamMedisList] = useState([]);
  const { currentUser } = useAuth();

  const handleSetIndex = (index) => {
    if (open === false) {
      activeIndex !== index && setActiveIndex(index);
    } else {
      setActiveIndex(0);
    }
  };

  const [paginationSetting, setPaginationSetting] = useState({
    limit: 5,
    filter_text: "",
  });

  const [pagination, setPagination] = useState({
    page: 0,
    totalPage: 0,
    totalResults: 0,
  });

  const recipeToLongString = (rekamMedisRow) => {
    // console.log("rekamMedisRow", rekamMedisRow);
    let outstring = "";
    let n = rekamMedisRow.length;
    rekamMedisRow.forEach((element, elementIdx) => {
      outstring = outstring.concat(element.label);
      outstring = outstring.concat(" ");
      outstring = outstring.concat(element.details);
      if (elementIdx < n - 1) {
        outstring = outstring.concat(",");
      }
    });

    return outstring;
  };

  useEffect(() => {
    let config = {
      // url: `/rekammedis/bypatient/?patient_id=${profile.patient_id}`,
      url: `/rekammedis/bypatient/?patient_id=${profile.patient_id}&user_uid=${currentUser?.uid}&page=${pagination.page}&limit=5`,
      method: "get",
    };
    AxiosFetch(config)
      .then((res) => {
        // console.log("rekamMedisList", res.data.data);
        // recipeToLongString();
        setRekamMedisList(res.data.data);
        setPagination({
          ...pagination,
          totalPage: res.data.totalPage,
          totalResults: res.data.totalResults,
        });
      })
      .catch((err) => setRekamMedisList([]));
  }, [profile, pagination.page]);

  useEffect(() => {
    if (activeIndex === 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [activeIndex]);

  return (
    <div className="min-h-screen mx-8">
      <ul className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
        {rekamMedisList.map((candidate, candidateIdx) => (
          <li key={candidateIdx}>
            <div
              className="block select-none group hover:cursor-pointer"
              onClick={() => handleSetIndex(candidateIdx + 1)}
            >
              <div className="flex items-center px-4 py-5 sm:py-6 sm:px-0">
                <div className="flex items-center flex-1 min-w-0">
                  <div className="flex-shrink-0">
                    {candidate.imageUrl ? (
                      <img
                        className="w-12 h-12 rounded-full group-hover:opacity-75"
                        src={candidate.imageUrl}
                        alt=""
                      />
                    ) : (
                      <span className="items-center justify-center hidden w-12 h-12 bg-gray-500 rounded-full sm:inline-flex">
                        <span className="text-xl font-medium leading-none text-center text-white ">
                          {candidate.name
                            .split(" ")
                            .slice(0, 2)
                            .map((elem) => elem[0])
                            .join("")}
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="flex-1 min-w-0 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-purple-600 truncate">
                        {`Dokter: ${candidate.doctor_name}`}
                      </p>
                      <p className="flex items-center mt-2 text-sm text-gray-500">
                        <span className="truncate">
                          klik untuk melihat detil riwayat
                        </span>
                      </p>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-900">
                          <time dateTime={candidate.complaint_datetime}>
                            {dateToHumanString(
                              new Date(candidate.complaint_date)
                            )}
                          </time>
                        </p>
                        <p className="flex items-center mt-2 text-sm text-gray-500">
                          {candidate.statusId === 0 ? (
                            <UsersIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          )}
                          {candidate.statusId === 0
                            ? "Masuk ke Antrean"
                            : "Selesai Diperiksa"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {activeIndex - 1 === candidateIdx ? (
                    <ChevronDownIcon
                      className="w-5 h-5 text-gray-400 group-hover:text-gray-700"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronRightIcon
                      className="w-5 h-5 text-gray-400 group-hover:text-gray-700"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              {activeIndex - 1 === candidateIdx && (
                <div>
                  <div className="flex items-center justify-start p-4 space-x-0 border-t border-gray-300 shadow-3xl rounded-2xl shadow-cyan-500/50">
                    <div className="w-48 p-3 bg-indigo-600 rounded-l-lg">
                      <h1 className="text-sm font-medium text-white text-bold">
                        Keluhan:
                      </h1>
                    </div>
                    <p className="w-full p-3 text-sm border border-gray-300 rounded-r-lg">
                      {candidate.complaint}
                    </p>
                  </div>

                  <div className="flex items-center justify-start p-4 space-x-0 border-t border-gray-300 shadow-3xl rounded-2xl shadow-cyan-500/50">
                    <div className="w-48 p-3 bg-indigo-600 rounded-l-lg">
                      <h1 className="text-sm font-medium text-white text-bold">
                        Diagnosa:
                      </h1>
                    </div>
                    <p className="w-full p-3 text-sm border border-gray-300 rounded-r-lg">
                      {candidate.diagnose}
                    </p>
                  </div>
                  <div className="flex items-center justify-start p-4 space-x-0 border-t border-gray-300 shadow-3xl rounded-2xl shadow-cyan-500/50">
                    <div className="w-48 p-3 bg-indigo-600 rounded-l-lg">
                      <h1 className="text-sm font-medium text-white text-bold">
                        Terapi:
                      </h1>
                    </div>
                    <p className="w-full p-3 text-sm border border-gray-300 rounded-r-lg">
                      {candidate.therapy}
                    </p>
                  </div>
                  <div className="flex items-center justify-start p-4 space-x-0 border-t border-gray-300 shadow-3xl rounded-2xl shadow-cyan-500/50">
                    <div className="w-48 p-3 bg-indigo-600 rounded-l-lg">
                      <h1 className="text-sm font-medium text-white text-bold">
                        Resep:
                      </h1>
                    </div>
                    <p className="w-full p-3 text-sm border border-gray-300 rounded-r-lg">
                      {recipeToLongString(candidate?.recipes || [])}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>

      {/* Pagination */}
      <Pagination
        pagination={pagination}
        paginationSetting={paginationSetting}
        setPagination={setPagination}
      />
    </div>
  );
}
