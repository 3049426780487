/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import cloneDeep from "lodash/cloneDeep";
import AxiosFetch from "../../utils/AxiosFetch";
import Select from "react-select";
import CustomDatePicker from "../../components/CustomDatePicker";
import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";

const dateToString = (inputDate) => {
  let month = inputDate.getMonth();
  let day = inputDate.getDate();
  let year = inputDate.getFullYear();
  return (
    String(year) +
    "-" +
    String(month).padStart(2, "0") +
    "-" +
    String(day).padStart(2, "0")
  );
};

const PASIEN_TITLE = "Pasien";
export default function EditData({
  editModalOpen,
  setEditModalOpen,
  headers,
  editUrl,
  getUrl,
  pagination,
  setData,
  mainTitle,
  mainDetails,
  inputValue,
  paginationSetting,
}) {
  const { currentUser } = useAuth();
  const cancelButtonRef = useRef(null);
  const [formValue, setFormValue] = useState({});
  const [defaultFormValue, setDefaultFormValue] = useState({});

  useEffect(() => {
    setFormValue(inputValue);

    let temp = {};
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].inputType === "dropdown") {
        for (let j = 0; j < headers[i].options.length; j++) {
          if (inputValue[headers[i].accessor] === headers[i].options[j].label) {
            temp = { ...temp, [headers[i].accessor]: headers[i].options[j] };
          }
        }
      } else {
        temp = {
          ...temp,
          [headers[i].accessor]: inputValue[headers[i].accessor],
        };
      }
    }

    setDefaultFormValue(temp);
  }, [inputValue, headers]);

  const handleRegister = () => {
    let formattedFormValue = cloneDeep(formValue);
    console.log("formattedFormValue", formattedFormValue);
    if (mainTitle === PASIEN_TITLE) {
      formattedFormValue = {
        ...formattedFormValue,
        registration_date: dateToString(formattedFormValue.registration_date),
        birthday: dateToString(formattedFormValue.birthday),
      };
    }
    let data = JSON.stringify(formattedFormValue);
    let config = {
      method: "put",
      url: `${editUrl}/${formattedFormValue?.id}`,
      headers: {
        accept: "application/json",
      },
      data: data,
    };

    AxiosFetch(config)
      .then((res) => {
        let getConfig = {
          method: "get",
          url: `${getUrl}/?user_uid=${currentUser.uid}&page=${pagination.page}&limit=${paginationSetting.limit}&filter_text=${paginationSetting.filter_text}`,
          headers: {
            accept: "application/json",
          },
        };

        AxiosFetch(getConfig).then((res) => setData(res.data.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Transition.Root show={editModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setEditModalOpen}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:min-w-96 sm:w-full sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-medium leading-6 text-gray-900"
                  >
                    {`Ubah Data ${mainTitle}`}
                  </Dialog.Title>
                  <div>
                    <p className="mt-1 text-sm text-gray-500">
                      {`${mainDetails} tepat`}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start my-4">
                  {headers.map((header_, headerIdx) => (
                    <div
                      key={headerIdx}
                      className="flex items-center justify-start w-full my-3 space-x-2"
                    >
                      <div className="w-40 text-sm font-medium text-gray-700">
                        {header_.Header}
                      </div>
                      {header_.inputType === "dropdown" ? (
                        <Select
                          className="text-sm w-80"
                          options={header_.options}
                          defaultValue={defaultFormValue[header_.accessor]}
                          onChange={(e) => {
                            setFormValue({
                              ...formValue,
                              [header_.accessor]: e.label,
                            });
                          }}
                          styles={{
                            input: (base) => ({
                              ...base,
                              "input:focus": {
                                boxShadow: "none",
                              },
                            }),
                          }}
                        />
                      ) : (
                        <>
                          {header_.inputType === "datepicker" ? (
                            <CustomDatePicker
                              formValue={formValue}
                              setFormValue={setFormValue}
                              paramToChange={header_.accessor}
                              initialDate={defaultFormValue[header_.accessor]}
                            />
                          ) : (
                            <input
                              disabled={header_.accessor === "registration_id"}
                              defaultValue={formValue[header_.accessor]}
                              autoComplete={header_.accessor}
                              className="flex-grow block border-gray-300 rounded-lg disabled:bg-gray-200 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-80 max-w-80"
                              type={header_.inputType || "text"}
                              pattern={
                                header_.inputType === "number" ? "d*" : null
                              }
                              onChange={(e) => {
                                if (header_.inputType === "number") {
                                  setFormValue({
                                    ...formValue,
                                    [header_.accessor]: parseInt(
                                      e.target.value
                                    ),
                                  });
                                } else {
                                  setFormValue({
                                    ...formValue,
                                    [header_.accessor]: e.target.value,
                                  });
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    handleRegister();
                    setEditModalOpen(false);
                  }}
                >
                  Ubah
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    setEditModalOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
