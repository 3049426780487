import { useEffect, useState } from "react";
import LocalNavbar from "../RekamMedis/LocalNavbar";
import Profile from "../RekamMedis/Profile";
import ProfileHeader from "./ProfileHeader";
import QueueBoard from "./QueueBoard";
import RekamMedisQueue from "./RekamMedisQueue";
import AxiosFetch from "../../utils/AxiosFetch";
import { pasienHeaders } from "../../components/TableHeaders";
import { dateToString } from "../Dokter/AddData";
import RekamMedisListAtAntrean from "./RekamMedisListAtAntrean";
// import { useAuth } from "../../components/FirebaseAuthComponents/context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Antrean() {
  const [queueList, setQueueList] = useState([]);
  const [currentQueue, setCurrentQueue] = useState({});
  const [parentUserId, setParentUserId] = useState("");
  const [obatSelection, setObatSelection] = useState([]);
  const [filterTextObat, setFilterTextObat] = useState("");
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    let todayDate = new Date();
    let config = {
      method: "get",
      url: `/queue?doctor_id=${
        JSON.parse(localStorage.getItem("loginInfo")).dokter_id
      }&nrow=5&complaint_date=${dateToString(todayDate)}`,
      headers: {
        accept: "application/json",
      },
    };
    AxiosFetch(config)
      .then((res) => {
        setQueueList(res.data.data);
      })
      .catch((err) => console.log("err", err));
  }, []);

  useEffect(() => {
    if (parentUserId !== "") {
      let config = {
        method: "get",
        url: `/obat/?user_uid=${parentUserId}&page=0&limit=10&filter_text=${filterTextObat}`,
      };

      AxiosFetch(config).then((res) => {
        let labelValueSelection = [];
        res.data.data.forEach((elem) => {
          labelValueSelection.push({
            value: elem.id,
            label: elem.name + ", " + elem.details,
          });
        });
        setObatSelection(labelValueSelection);
      });
    }
  }, [parentUserId, filterTextObat]);

  useEffect(() => {
    let config = {
      method: "get",
      url: `/parentuseridbydokterid?dokter_id=${
        JSON.parse(localStorage.getItem("loginInfo")).dokter_id
      }`,
      headers: {
        accept: "application/json",
      },
    };

    AxiosFetch(config).then((res) => {
      setParentUserId(res.data.user_uid);
    });
  }, []);

  useEffect(() => {
    setCurrentQueue(queueList[0]);
  }, [queueList]);
  const [profilPasien, setProfilPasien] = useState({
    name: "",
    fields: {
      // Phone: "(555) 123-4567",
      // Email: "ricardocooper@example.com",
      // Title: "Senior Front-End Developer",
      // Team: "Product Development",
      // Location: "San Francisco",
      // Sits: "Oasis, 4th floor",
      // Salary: "$145,000",
      // Birthday: "June 8, 1990",
      // Contoh: "contoh lagi",
      // Yagitu: "gitu lagi ",
    },
  });

  useEffect(() => {
    if (queueList.length > 0) {
      let config = {
        method: "get",
        url: `/rekammedis/profile?uid=${currentQueue.patient_id}`,
        accept: "application/json",
      };
      AxiosFetch(config).then((res) => {
        let temp = {};
        let profileName = "";
        for (let i = 0; i < pasienHeaders.length; i++) {
          if (pasienHeaders[i].inputType === "dropdown") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] ||
                pasienHeaders[i].default.label,
            };
          } else if (pasienHeaders[i].inputType === "datepicker") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]: dateToString(
                new Date(res.data[pasienHeaders[i].accessor]) ||
                  pasienHeaders[i].default
              ),
            };
          } else if (pasienHeaders[i].accessor === "name") {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default,
            };

            profileName =
              res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default;
          } else {
            temp = {
              ...temp,
              [pasienHeaders[i].Header]:
                res.data[pasienHeaders[i].accessor] || pasienHeaders[i].default,
            };
          }
        }
        setProfilPasien({
          ...profilPasien,
          fields: temp,
          name: profileName,
          patient_id: currentQueue.patient_id,
        });
      });
    }
  }, [currentQueue]);

  const tabs = [
    { name: "Buat Rekam Medis", href: "rekam", current: true },
    { name: "Profil Pasien", href: "profil", current: false },
    { name: "Riwayat Rekam Medis", href: "riwayat", current: false },
  ];

  const [tabSelected, setTabSelected] = useState([
    { name: "Buat Rekam Medis", href: "rekam", current: true },
    { name: "Profil Pasien", href: "profil", current: false },
    { name: "Riwayat Rekam Medis", href: "riwayat", current: false },
  ]);

  return (
    <>
      <LocalNavbar profile={profilPasien} setProfile={setProfilPasien} />
      <div className="flex justify-between">
        <div className="flex mt-16 flex-col flex-1 min-w-0 overflow-hidden sm:ml-screen2.5/10 sm:w-screen1/2 w-full">
          <div className="relative flex flex-1 overflow-hidden ">
            <main className="relative flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                {/* Profile header */}
                <ProfileHeader
                  profile={profilPasien}
                  queueList={queueList}
                  currentQueue={currentQueue}
                  setCurrentQueue={setCurrentQueue}
                  finished={finished}
                  setFinished={setFinished}
                />
                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200">
                    <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                      <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        {tabs.map((tab, tabIdx) => (
                          <button
                            key={tab.name}
                            href={tab.href}
                            onClick={() => {
                              let temp = [];
                              for (let i = 0; i < tabs.length; i++) {
                                if (tabs[i].href === tab.href) {
                                  temp.push({ ...tab, current: true });
                                } else {
                                  temp.push({ ...tab, current: false });
                                }
                              }

                              setTabSelected(temp);
                            }}
                            className={classNames(
                              tabSelected[tabIdx].current
                                ? "border-pink-500 text-gray-900"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            {tab.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* Profil list */}
                {tabSelected[0].current ? (
                  <RekamMedisQueue
                    currentQueue={currentQueue}
                    setCurrentQueue={setCurrentQueue}
                    obatSelection={obatSelection}
                    setObatSelection={setObatSelection}
                    setFilterTextObat={setFilterTextObat}
                  />
                ) : (
                  ""
                )}
                {tabSelected[1].current ? (
                  <Profile profile={profilPasien} />
                ) : (
                  ""
                )}

                {tabSelected[2].current ? (
                  <RekamMedisListAtAntrean
                    profile={profilPasien}
                    parentUserId={parentUserId}
                  />
                ) : (
                  ""
                )}

                {/* <TeamMemberList team={team} /> */}
              </article>
            </main>
          </div>
        </div>
        <div className="w-full mt-16 bg-slate-200 min-h-screen lg:w-screen2.5/10">
          <QueueBoard
            queueList={queueList}
            setQueueList={setQueueList}
            finished={finished}
            setFinished={setFinished}
            setCurrentQueue={setCurrentQueue}
          />
        </div>
      </div>
    </>
  );
}
