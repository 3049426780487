import React from "react";
import Dokter from "../Dokter/Dokter";
import { ruangHeader } from "../../components/TableHeaders";

const QUERY_RUANG = "/ruang";

const Ruang = () => {
  return (
    <Dokter
      queryUrl={QUERY_RUANG}
      headers={ruangHeader}
      mainTitle="Ruang"
      mainDetails="Masukkan Data Ruangan"
    />
  );
};

export default Ruang;
